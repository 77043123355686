@import '../../scss/colors';

footer.page-footer {
  background: $footer-gradient;

  h5 {
    padding-top: 1em;
  }

  .btn-flat:hover {
    background: $purple !important;
    border-radius: 4px;
  }
}
