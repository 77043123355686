#server-benchmarks {
  width: 80%;
  margin: 0 auto;

  @media(max-width:768px) {
    width: 100%;
  }

  h6 {
    margin: 0 auto;
    padding-bottom: 3em;
    width: 60%;

    @media(max-width:768px) {
      width: 100%;
    }
  }

  // .specs {
  //   padding: 1em 0;
  // }

  // .grid-test {
  //   display: block;
  //   width: 100%;
  //   height: 300px;
  //   margin: 0 auto;

  //   @media(min-width:1024px) {
  //     display: grid;
  //     grid-template-columns: 50% 50%;
  //     //align-items: center;
  //   }
  // }

  .bench-color {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
  }
}