@import '../../scss/colors';

#syntax {
  h6 {
    width: 50%;
    margin: 0 auto;
    padding-bottom: 3em;

    @media(max-width:768px) {
      width: 100%;
    }
  }

  #syntax-collection .collection-item {
    background: $code-background;
  }

  .keyword {
    color: $code-keyword;
    font-weight: bold;
  }
}