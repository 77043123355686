@import '../../scss/colors';

#benchmark {
  margin: 0 2em;
  width: 75%;
  padding: 1em 0 2em;
  margin: 0 auto;

  @media(max-width:768px) {
    width: 80%;
  }

  .bench-color {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
  }

  table {
    box-shadow: 0 0 3px #ddd;
  }
}
.ct-series-b .ct-line,
.ct-series-b .ct-point {
  stroke: green;
}