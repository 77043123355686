@import '../../../scss/colors';

.code {
  background: $code-background;
  border-radius: 10px;
  padding: 0rem;

  pre {
    border-radius: 10px;
  }
}