@import '../../scss/colors';

header {
  background: $header-gradient;
  color: $brand-white;
  padding: 100px 0 0 0;

  img {
    display: block;
    margin: 0 auto 1.25em;
    max-width: 300px;
  }

  .name {
    display: block;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2em;

    @media(min-width:768px) {
      font-size: 4.75em;
    }
  }

  .skills {
    font-size: 1.25em;
    font-weight: 300;

    @media(min-width:768px) {
      font-size: 1.75em;
    }
  }
}