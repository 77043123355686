$background:rgb(252, 252, 252);
$secondary: rgb(29, 125, 216);
$accent: rgb(39, 171, 224);
$heading: rgb(48, 94, 221);

$brand-black: #2C3E50;
$brand-success: #18BC9C;
$brand-white: #f6f7f8;
$gray-lighter: lighten(#000, 93.5%);
$brand-purple: #2e0741;
$brand-pink: #ff79c6;
$brand-blue: #20a4f3;
$brand-black-light: lighten(#000, 93.5%);

$body-background: #020024;
$code-background: rgb(39, 40, 34);
$code-keyword: rgb(249, 38, 114);
$purple: #6a1b9a;

$header-gradient: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(46,7,65,1) 60%);
$footer-gradient: linear-gradient(0deg, rgba(46,7,65,1) 60%, rgba(2,0,36,1) 100%);
