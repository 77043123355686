@import '../../scss/colors';

#examples {
  width: 50%;
  margin: 0 auto;

  @media(max-width:768px) {
    width: 100%;
  }

  h2 {
    color: $brand-white;
  }

  .examples-component {
    color: $brand-black;
  }

  .card {
    background: rgba(0, 0, 0, 0);
  }

  .card-image {
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }

  .card-content,.card-action {
    background: $code-background;
    color: white;
  }

  .card-action {
    border-radius: 0 0 5px 5px;
  }
}