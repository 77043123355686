.graph {
  height: 500px;
  box-shadow: 0 0 8px 0px #ddd;
  padding: 8px;

  text {
    color: white;
    fill: white !important;
  }

  div {
    background: #020024 !important;
  }
}